<template>
  <card>
    <div class="custom-container">
      <h3 class="p-3">
        <b>Sistema de Gestión : PESV</b>
      </h3>

      <div class="row mx-3 d-flex justify-content-center" style="gap: 0.5rem">
        <div v-for="(fase, index) of fases" :key="fase.id"
          class="col-12 col-md-4  text-white   p-3 rounded d-flex justify-content-center" :style="{
            transition: 'box-shadow 0.3s ease',
            background: activeFase == index ? '#6272ff' : '#343d93',
            cursor: 'default',
          }" @click="handlerActiveFase(index)">
          {{ fase.text }}
          <!-- <i class="fas fa-circle-check icon" :class="{
            show: activeFase == index,
          }"></i> -->
        </div>
      </div>
      <hr />

      <ListEncuesta :fase="activeFase" />
    </div>
  </card>
</template>

<script>
import ListEncuesta from "./ListaEncuestas/ListEncuesta.vue";

export default {
  components: {
    ListEncuesta,
  },
  data() {
    return {
      activeFase: 0,
      fases: [
        {
          id: 1,
          text: "Formulario Fase 1",
        },
        {
          id: 2,
          text: "Formulario Fase 2",
        },
      ],
    };
  },
  methods: {
    handlerActiveFase(index) {
      this.activeFase = index;
    },
  },
};
</script>

<style scoped>
.icon {
  opacity: 0;
  transition: 0.3s;
}

.icon.show {
  opacity: 1;
  transform: scale(1.2);
}
</style>
